import setCurrentUrl from "@/common/utils/setCurrentUrl";
import {
  TrialsCtaButton,
  TrialsCtaContainer,
  TrialsNoCreditCard,
} from "@/components/shared/trials/trials.styles";
import { useAppDispatch } from "@/store";
import { setShowTrialsModal } from "@/store/slices/auth.slice";
import { setPricePlan } from "@/store/slices/pricing.slice";
import { useTranslation } from "next-i18next";
import { usePathname, useRouter } from "next/navigation";
import { TrialsCreditCard } from "./TrialsCreditCard";
import { ENV_CONSTANTS } from "@/common/constants/env.const";
import routes from "@/common/constants/routes";

interface TrialsCtaProps {
  plan: string;
  payment: string;
}

export const TrialsCta = ({ plan, payment }: TrialsCtaProps) => {
  const { t } = useTranslation("trials");
  const router = useRouter();
  const dispatch = useAppDispatch();
  const pathName = usePathname();
  const isUpgrade = plan === "registered-user" && payment;

  const buttonText = isUpgrade
    ? "trials.modal.button.upgrade"
    : "trials.modal.button.start";

  const startTial = () => {
    if (plan === "registered-user" && !payment) {
      return localStorage.setItem("startTrial", "true");
    }

    return localStorage.setItem("startTrial", "false");
  };

  const handleClick = async () => {
    await startTial();
    await dispatch(setPricePlan("solo"));
    setCurrentUrl(pathName);
    dispatch(setShowTrialsModal(false));
    router.push(routes.CHECKOUT);
  };

  return (
    <TrialsCtaContainer>
      <TrialsCtaButton variant="outlinedSquared" onClick={() => handleClick()}>
        {t(`${buttonText}`, { days: ENV_CONSTANTS.STRIPE_TRIAL_PERIOD })}
      </TrialsCtaButton>
      {!isUpgrade && (
        <TrialsNoCreditCard container>
          <TrialsCreditCard />
        </TrialsNoCreditCard>
      )}
    </TrialsCtaContainer>
  );
};
